<script>
export default {
  props: {
    paged: Number,
    show: Number,
    showing: Number,
    found: Number,
  },

  computed: {
    countFirst() {
      if (!this.showing) return 0;
      let countFirst = 1;
      if (this.paged > 1) {
        countFirst += this.show * (this.paged - 1);
      }
      return countFirst;
    },
    countLast() {
      if (!this.countFirst) return 0;
      return this.countFirst + this.showing - 1;
    },
  },
};
</script>

<template>
  <div class="entries_showing">
    Mostrando
    <template v-if="countFirst < countLast">
      del <b>{{ countFirst }}</b> al <b>{{ countLast }}</b> de
    </template>
    <template v-else>
      <b>{{ countFirst }}</b> de
    </template>
    <b>{{ found }}</b>
  </div>
</template>
